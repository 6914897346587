/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';




:root {
    --clr-text: #5a5a5a;
    --clr-primary: #FF6600 ;
    --clr-secondary: #007AC9 ;
    --clr-accent: #595959;
    --clr-darker: #19557d;
    --clr-gray: #ADADAD;
    --clr-black: #000000;
    
    --ff-title: "Roboto", sans-serif;
    --ff-serif: "Roboto", serif;
    
}

*,
*::before,
*::after{
    box-sizing: inherit;
}


html{
    box-sizing: border-box;
    color: var(--clr-text);
    font-family: var(--ff-serif);
    font-size: 1.125rem;
}

body {
 margin: 0;   
     font-family: var(--ff-serif);

}

img {
    display: block;
    max-width: 100%;
}

/* Typography */
h1,h2,h3,h4,p {
    margin: 0;
}

h2, h3 {
    font-family: var(--ff-title);
    line-height: 0.8;
}

h2 {
    font-size: 2rem;
}

p {
    margin-bottom: 0.85rem;
        font-family: var(--ff-serif);

}

p:last-child {
    margin-bottom: 0;
}

b, strong {
    font-weight: 900;
}

.text-primary {
    color: var(--clr-primary);
}

.text-accent {
    color: var(--clr-accent);
}
/* / Typography */

/* Buttons */
.btn-primary {
    color: #fff;
    background-color: var(--clr-primary);
    border-color: var(--clr-primary);
}
.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: var(--clr-accent);
    border-color: var(--clr-accent);
}
.btn-primary:hover {
    color: #fff;
    background-color: var(--clr-accent);
    border-color: var(--clr-accent);
}
.bg-primary {
    background-color: var(--clr-primary)!important;
}

/* / Buttons */

input[type="checkbox"] {
    width: 2rem;
    height: 2rem;
    top: 3px;
    margin-right: 1rem;
    margin-top: -5px;
  }
  input[type="checkbox"]:after {
    width: 2rem;
    height: 2rem;
    position: relative;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 3px solid var(--clr-primary);
  }
  
  input[type="checkbox"]:checked:after {
    width: 2rem;
    height: 2rem;
  
    position: relative;
    background-color: var(--clr-primary);
    content: "";
    display: inline-block;
    visibility: visible;
    border: 3px solid var(--clr-primary);
  }

  input[type="radio"] {
    width: 2rem;
    height: 2rem;
    top: 3px;
    margin-right: 1rem;
    margin-top: -5px;
  }
  input[type="radio"]:after {
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    position: relative;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 3px solid var(--clr-primary);
  }
  
  input[type="radio"]:checked:after {
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
  
    position: relative;
    background-color: var(--clr-primary);
    content: "";
    display: inline-block;
    visibility: visible;
    border: 3px solid var(--clr-primary);
  }

  label {
      display: flex;
      margin-bottom: 2rem;
  }

  .modal-content {
    position: relative;
    display: flex;
     flex-direction: row; 
    justify-content: center;
    width: 100%;
    pointer-events: auto;
     background-color:unset;
     border: unset;
    outline: 0;
}


.progress {
    border: 1px solid var(--clr-primary);
}
.progressbaroverall .progress {
    border: 2px solid var(--clr-primary);
    height: 2rem;
}

// #SvgjsSvg1042 text {
//     fill: red!important;
//     font-size: 15px!important;
//     opacity: 0!important;
// }

// @page { size: auto;  margin: 0mm; }
.submission button {
    color: var(--clr-primary);
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .sectionTitle {
  
    font-size:1.8rem!important;
    // width: 40%!important;
    // width: unset!important;
  
}
@media only screen and (max-width: 1000px)  {
    .twocols {
        grid-template-columns: 1fr!important;
    }
}
@media only screen and (max-width: 600px)  {
    .contentright {
        h2 br {
            display:none;
        }
    }

    input[type="checkbox"]:after {
        width: 1.9rem;
        height: 1.9rem;
      }
    input[type="radio"]:after {
        width: 1.9rem;
        height: 1.9rem;
      }
    
}



